const envMap = {
  localhost: "https://localhost:4000",
  dev: "https://8x5q0lbfjd.execute-api.eu-central-1.amazonaws.com",
  prd: "https://nog0yazwyf.execute-api.eu-central-1.amazonaws.com",
};

const currentEnv = process.env.REACT_APP_NODE_ENV || 'prd';

const config = {
  // +++++++++++++++++
  serverUrl: envMap[currentEnv] || envMap.prd, // Fallback a 'prd' if environment variable is not set.
  // +++++++++++++++++
  beta: false, // Change to "true" to activate beta mode: Display a banner on bottom page.
  // +++++++++++++++++
  banner_christmas_break: true, // Display info banner about Christmas break.
  // +++++++++++++++++
  maintenance: false, // Change to "true" to activate maintenance mode: Display page with maintenance message.
  // +++++++++++++++++
};

export default config;
