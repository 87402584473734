import axios from 'axios';
import config from '../config';
import useStore from '../store/store';
import useBrandStore from '../store/brandStore';
import { useNavigate, useLocation } from 'react-router-dom';

const useHandleSearchService = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchService = async (searchParams = {}, isReplacementSearch = false) => {
    const {
      searchQuery,
      exactMatch,
      setSearchResults,
      searchQuerySnap,
      setSearchQuerySnap,
      setBrands_in_results,
      setOnStock_in_results,
      setSearchType,
      setSearchError,
      setSearchedBrand,
      setCurrentPage,
      setHasSearched,
      filter__brands,
      filter__onstock,
      setIsLoadingResults,
      setIsReplacementSearch,
      setSearchErrorType,
      user,
      loading_From_URL, 
      setLoading_From_URL,
      pricetool_brands,
      language,
      searchError,
    } = useStore.getState();

    const { 
      brandMapping, 
      searchedBrandData, 
      setSearchedBrandData 
    } = useBrandStore.getState();

    const query = searchParams.q || (loading_From_URL ? location.pathname.split('/')[2] : searchQuery);
    const onstock = searchParams.onstock !== undefined ? searchParams.onstock : filter__onstock;
    const brands = searchParams.brands || (filter__brands || []);

    if (loading_From_URL) {
      setLoading_From_URL(false);
    }

    const updateURL = () => {
      if (searchError) return;
      const queryStr = searchQuery || 'all'; // Show original query in URL
      const brandStr = brands.length > 0 ? brands.join(',') : '';
      const onStockStr = onstock ? 'onstock' : '';

      const filterPart = brandStr ? `filter/${brandStr}` : '';
      const onStockPart = onStockStr ? `/${onStockStr}` : '';

      const newUrl = `/product/${queryStr}${filterPart ? '/' + filterPart : ''}${onStockPart}`;
      if (newUrl !== location.pathname) {
        navigate(newUrl);
      }
    };

    if (!query || query.trim() === '') {
      setIsLoadingResults(false);
      return;
    }

    // Avoid unnecessary API calls if the search query is the same as the previous one:
    const filterChanged = JSON.stringify(filter__brands) !== JSON.stringify(searchQuerySnap.filter__brands || []);
    if (
        searchQuery.trim().toLowerCase() === searchQuerySnap.trim().toLowerCase() && 
        !filterChanged && filter__brands.length > 0
      ) {
      setIsLoadingResults(false);
      return;
    }
    

    // ------------------------------------
    // Check if search for a brand or article:
    setSearchedBrand(null);
    
    if (!brandMapping[query] && !pricetool_brands?.includes(query)) {
      if (searchedBrandData !== null) {
        setSearchedBrandData(null);
      }
    }    

    let searchType = 'article';
    const normalizedQuery = query.trim().toLowerCase();
    const foundBrand = pricetool_brands?.find(brand => brand.toLowerCase() === normalizedQuery);

    if (foundBrand) {
      searchType = 'brand';
      setSearchedBrand(foundBrand);
      //console.log('BrandInfoPanel.js: foundBrand:', brandMapping[foundBrand]);
      setSearchedBrandData({ ...brandMapping[foundBrand] });
    } else {
      const matchedBrandKey = Object.keys(brandMapping).find(
        key => key.toLowerCase() === normalizedQuery
      );    
      if (matchedBrandKey) {
        searchType = 'alternative_brand';
        setSearchedBrand(brandMapping[matchedBrandKey].brand);
        setSearchedBrandData({ ...brandMapping[matchedBrandKey] });
      }
    }
    setSearchType(searchType);
    // ------------------------------------

    setIsLoadingResults(true);    
    const minimumLoadingTime = new Promise((resolve) => setTimeout(resolve, 1500));

    const params = {
      q: query.trim().replace(/^"|"$/g, ''),
      userid: (user && user.id) || null,
    };
    if (exactMatch) params.exact = true;
    if (brands.length > 0) params.brands = JSON.stringify(brands);
    if (onstock) params.onstock = onstock;

    const token = localStorage.getItem('token');
    if (!token) {
      setSearchError(true);
      setSearchErrorType('unauthorized');
      setIsLoadingResults(false);
      return;
    }

    try {
      const cleanedQuery = query.replace(/\*/g, '').trim();
      // Validate search query, accepting only alphanumeric characters, spaces, accents and "umlauts":
      if (!cleanedQuery || /[^a-zA-Z0-9\sÀ-ÿ%+./-]/.test(cleanedQuery)) {
          setSearchError(true);
          setSearchErrorType('invalid_query');
          setSearchQuerySnap(query);
          setSearchResults([]);
          return;
      }

      const response = await axios.get(`${config.serverUrl}/search`, {
        params,
        headers: { 
          Authorization: `Bearer ${token}`,
          'X-Selected-Language': language,
       },
        withCredentials: true,
      });
      
      // No results:
      if (response.data.articles.length === 0) {
        if (searchType === 'brand') {
          setSearchErrorType('brand_search');
        } else {
          setSearchErrorType('no_results');
        }
        setSearchQuerySnap(query);
        setSearchResults([]);
        setSearchError(true);
        setIsLoadingResults(false);
        return;
      }
    

      // If no results found and not already performing a "replacement" search
      // ----------------------------------------------------------------------
      // NOTE: This is a temporary workaround to disable replacement search.
      // PLEASE REMOVE "false" WHEN REPLACEMENT SEARCH IS ENABLED AGAIN.
      // ----------------------------------------------------------------------
      if (false && response.data.articles.length === 0 && !isReplacementSearch) {
        // Execute replacement query if no articles are found
        const replacementResponse = await axios.get(`${config.serverUrl}/replacements`, {
          params: { q: query },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });

        // If replacement articles are found, perform sequential search
        if (replacementResponse.data.replacements.length > 0) {
          const replacementQueries = replacementResponse.data.replacements;
          
          setIsReplacementSearch(true); // Mark as replacement search in Zustand
          
          // Accumulate results from each replacement query
          const accumulatedResults = [];
          const accumulatedBrands = [];
          let accumulatedOnStock = 0;

          // Sequentially search each replacement query
          for (const replacementQuery of replacementQueries) {
            // Perform search for each replacement article
            const replacementResult = await axios.get(`${config.serverUrl}/search`, {
              params: { q: replacementQuery, userid: params.userid },
              headers: { Authorization: `Bearer ${token}` },
              withCredentials: true,
            });

            if (replacementResult.data.articles.length > 0) {
              accumulatedResults.push(...replacementResult.data.articles);
              accumulatedBrands.push(...replacementResult.data.brands);
              accumulatedOnStock += replacementResult.data.onStock;
            }
          }

          // Check if any results were accumulated
          if (accumulatedResults.length > 0) {
            setHasSearched(true);
            setSearchResults(accumulatedResults);
            setBrands_in_results(accumulatedBrands);
            setOnStock_in_results(accumulatedOnStock);
            setSearchError(false);
            setSearchErrorType(null);
            setSearchQuerySnap(searchQuery);  // Keep original query in Snap
            updateURL();
            return;
          }
        }

        // If no results in replacement search, show error
        setSearchError(true);
        setSearchErrorType('no_replacements');
        setSearchResults([]);  // Clear results
        setSearchQuerySnap(searchQuery);  // Keep original query in Snap
        setIsLoadingResults(false);
        setBrands_in_results([]);
        setOnStock_in_results(0);
        return;
      }

      // If results found in original or replacement search
      setHasSearched(true);
      setSearchResults(response.data.articles);
      setBrands_in_results(response.data.brands);
      setOnStock_in_results(response.data.onStock);
      setSearchError(false);
      setSearchErrorType(null);
      setSearchQuerySnap(query);
      updateURL();

      // Check if there is only one brand in the results and show its info:
      if (response.data.brands.length === 1) {
        const singleBrand = response.data.brands[0].brand;
        const brandData = brandMapping[singleBrand];
        // Check if brand data is available
        if (brandData && (brandData.message || brandData.info)) {
          setSearchedBrandData(brandData);
        }
      }

      // Update URL with search query and filters
      const newUrl = `/product/${query}${brands.length > 0 ? `/filter/${brands.join(',')}` : ''}${onstock ? '/onstock' : ''}`;
      if (newUrl !== location.pathname) {
        navigate(newUrl);
      }
    } catch (error) {
      if (error.response?.status === 400) {
        // Handle invalid query error
        setSearchError(true);
        setSearchErrorType('invalid_query');
        setSearchResults([]);
        setBrands_in_results([]);
        setOnStock_in_results(0);
        setSearchQuerySnap(query);
      } else if (error.response?.status === 401) {
        // Redirection to login page
        window.location.href = '/login';
      } else {
        // Other server errors
        setSearchError(true);
        setSearchErrorType('server_error');
      }
    } finally {
      setCurrentPage(1);
      await minimumLoadingTime;
      setIsLoadingResults(false);
    }
  };

  return { handleSearchService };
};

export default useHandleSearchService;
