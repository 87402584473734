/**
 * ZUSTAND STORE
 * https://github.com/pmndrs/zustand
 * https://pmndrs.github.io/zustand
 * 
 * How to use: 
 * The store is a hook. You can put anything in it: primitives, objects, functions. 
 * State has to be updated immutably and the "set" function merges state to help it.
 * 
 * Use the hook anywhere, no providers are needed. 
 * Select your state and the component will re-render on changes.
 * Just call "set" when you're ready, zustand doesn't care if your actions are async or not.
 */
import { create } from 'zustand';

const initialState = {
  // LANGUAGE:
  // +++++++++++++++++
  language: localStorage.getItem("language") || "EN",
  // LOGIN:
  // +++++++++++++++++
  isLoadingUser: false,
  token: localStorage.getItem("token") || null,
  rememberMe: localStorage.getItem("rememberMe") === "true" || localStorage.getItem("rememberMe") === null,


  // @ts-ignore
  loginAttempts: JSON.parse(localStorage.getItem("loginAttempts")) || 0,
  // @ts-ignore
  user: JSON.parse(localStorage.getItem("user")) || null,
  // PRICETOOL BRANDS:
  // +++++++++++++++++
  // @ts-ignore
  pricetool_brands: JSON.parse(localStorage.getItem("pricetool_brands")) || [],
  is_loading_pricetool_brands: true,
  // SEARCH ENGINE:
  // +++++++++++++++++
  loading_From_URL: false,
  shouldFocus: false,  
  searchQuery: "",
  searchQuerySnap: "",
  searchErrorType: null, // Can be: 'no_results', 'brand_search', 'blocked_brand', etc.
  searchType: null, // Can be: 'article', 'brand' or 'alternative_brand'
  searchedBrand: null,
  exactMatch: false,
  searchResults: [],
  brands_in_results: [],
  onStock_in_results: 0,
  filter__brands: [],
  filter__onstock: false,
  isLoadingResults: false,
  searchError: false,
  hasSearched: false,
  currentPage: 1,
  resultsPerPage: 5,
  // @ts-ignore
  total_articles: JSON.parse(localStorage.getItem("total_articles")) || "0",  
  resetAccordions: false,  
  isReplacementSearch: false,
};

const useStore = create((set) => ({
  ...initialState,
  // LANGUAGE SETTERS:
  // +++++++++++++++++
  setLanguage: (language) => {
    localStorage.setItem("language", language);
    set({ language });
  },
  // LOGIN SETTERS:
  // +++++++++++++++++
  setToken: (token) => {
    localStorage.setItem("token", token);
    set({ token });
  },
  setUser: (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    set({ user });
  },
  setRememberMe: (rememberMe) => {
    localStorage.setItem("rememberMe", rememberMe);
    set({ rememberMe });
  },
  setIsLoadingUser: (isLogin) => set({ isLoadingUser: isLogin }),
  incrementLoginAttempts: () => {
    set((state) => {
      const newAttempts = state.loginAttempts + 1;
      localStorage.setItem("loginAttempts", JSON.stringify(newAttempts));
      return { loginAttempts: newAttempts };
    });
  },
  resetLoginAttempts: () => {
    localStorage.setItem("loginAttempts", JSON.stringify(0));
    set({ loginAttempts: 0 });
  },
  resetSearch: () => {    
    set({
      searchQuery: "",
      searchQuerySnap: "",
      exactMatch: false,
      searchResults: [],
      brands_in_results: [],
      onStock_in_results: 0,
      filter__brands: [],
      filter__onstock: false,
      searchError: false,
      hasSearched: false,
      isLoadingResults: false,
      currentPage: 1,
      isReplacementSearch: false,
    });
  },
  clearAuth: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("loginAttempts");
    localStorage.removeItem("total_articles");
    localStorage.removeItem("pricetool_brands");    
    set({ token: null, user: null, rememberMe: false });
  },
  setResetAccordions: (value) => set({ resetAccordions: value }),

  // BRANDS SETTERS:
  // +++++++++++++++++
  setPricetool_brands: (brands) => {
    localStorage.setItem("pricetool_brands", JSON.stringify(brands));
    set({ pricetool_brands: brands, is_loading_pricetool_brands: false });
  },
  loadPricetoolBrandsFromLocalStorage: () => {
    const localBrands = localStorage.getItem("pricetool_brands");
    if (localBrands) {
      set({ pricetool_brands: JSON.parse(localBrands), is_loading_pricetool_brands: false });
    } else {
      set({ is_loading_pricetool_brands: false });
    }
  },
  resetLoadingPricetoolBrands: () => set({ is_loading_pricetool_brands: true }),
  
  // SEARCH ENGINE SETTERS:
  // +++++++++++++++++++++++
  setLoading_From_URL: (value) => set({ loading_From_URL: value }),
  setShouldFocus: (value) => set({ shouldFocus: value }),
  setSearchQuery: (query) => set({ searchQuery: query }),
  setSearchQuerySnap: (query) => set({ searchQuerySnap: query }),
  setExactMatch: (match) => set({ exactMatch: match }),
  setSearchResults: (results) => set({ searchResults: results }),
  setBrands_in_results: (results) => set({ brands_in_results: results }),
  setBrands_in_results_old: (searchResults) => {
    const brands = Array.from(new Set(searchResults.map(result => result.brand)));
    set({ brands_in_results: brands });
  },
  setOnStock_in_results: (results) => set({ onStock_in_results: results }),
  setFilter__brands: (brands) => set({ filter__brands: brands }),
  setFilter__onstock: (onStock) => set({ filter__onstock: onStock }),
  setIsLoadingResults: (isLoading) => set({ isLoadingResults: isLoading }),
  setSearchError: (isSearchError) => set({ searchError:isSearchError }),
  setHasSearched: (searched) => set({ hasSearched: searched }),
  setCurrentPage: (page) => set({ currentPage: page }),
  setTotalArticles: (count) => {
    localStorage.setItem("total_articles", count);
    set({ total_articles: count })
  },
  setIsReplacementSearch: (value) => set({ isReplacementSearch: value }),
  setSearchErrorType: (type) => {
    //console.log("Setting searchErrorType to:", type);
    set({ searchErrorType: type })
  },
  setSearchType: (type) => {
    //console.log("Setting searchType to:", type);
    set({ searchType: type })
  },
  setSearchedBrand: (type) => {
    //console.log("Searched brand:", type);
    set({ searchedBrand: type })
  },
  
  
  // RESET STORE:
  // +++++++++++++++++
  resetStore: () => {
    // Clear local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("total_articles");
    localStorage.removeItem("pricetool_brands");
    // Reset state
    set(initialState);
  },
}));

// Expose the store in the window object for debugging
// Usage: 
// - window.useStore.getState()
// - window.useStore.getState().searchResults
if (typeof window !== "undefined" && (window.location.hostname === "localhost" || window.Cypress)) {
  // @ts-ignore
  window.useStore = useStore;
}
export default useStore;
