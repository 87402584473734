import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStore from "../../store/store";
import BrandInfoPanel from './BrandInfoPanel';

const SearchErrorMessage = () => {
  const { t } = useTranslation();
  const { searchErrorType, searchQuerySnap, searchedBrand } = useStore();

  if (!searchErrorType) return null;

  const errorMessages = {
    no_results: t('resultArticlesNotFoundAnything'),
    brand_search: t('brandSearchMessage', 'This brand is in our catalog, please search for articles.'),
    unauthorized: t('unauthorizedMessage', 'You are not authorized. Please log in.'),
    invalid_query: t('invalidQueryMessage', 'Invalid search query. Please try again.'),
    server_error: t('serverErrorMessage', 'An error occurred. Please try again later.')
  };

  return (
    <>
    <Box sx={{ fontSize: 14, height: 'auto', textAlign: 'center' }}>
      <Typography sx={{ fontSize: 100 }}>
        {searchErrorType === 'brand_search' ? '✏️' : '⚠️'}
      </Typography>
      
      <Typography sx={{ fontSize: 30, fontWeight: 'bold', wordBreak: 'break-word', lineHeight: 1 }}>
        {searchQuerySnap}
      </Typography>

      <Typography sx={{ fontSize: 16 }}>
        {errorMessages[searchErrorType]}
      </Typography>
    </Box>
    {searchedBrand && (
      <Box sx={{ backgroundColor: 'white' }} data-testid="brand-message">
        <BrandInfoPanel />
      </Box>
    )}
    </>
  );
};

export default SearchErrorMessage;
