import toast from 'react-hot-toast';
import config from '../config';
import useStore from '../store/store';
import i18n from 'i18next';

export const brandsService = async (token) => {
  try {
    //useStore.getState().pricetool_brands.length > 0 && (() => { return })();
    const brandsResponse = await fetch(`${config.serverUrl}/brands`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      credentials: 'include',
    });

    if (!brandsResponse.ok) {
      throw new Error('Failed to fetch brands');
    }

    const brandsData = await brandsResponse.json();
    const total_brands = brandsData.brands;
    const { setPricetool_brands, user } = useStore.getState();
    
    // Get blocked brands from user object
    const blockedBrandsString = user.blocked_brands || ""; // Ensure it's a string
    const blockedBrands = blockedBrandsString
      .split(",") // Split by comma
      .map(brand => brand.trim()) // Trim whitespace
      .filter(brand => brand !== ""); // Remove empty strings
    
    // Filter out blocked brands
    const filteredBrands = total_brands.filter(
      brand => !blockedBrands.includes(brand)
    );

    // Update Zustand state with filtered brands
    setPricetool_brands(filteredBrands);
    
  } catch (brandsError) {
    console.error('Error fetching brands:', brandsError);
    toast.error('Failed to fetch brands');
  }
};


/**
 * Determines if the BrandInfoPanel should be displayed.
 * @param {string} searchType - The type of search ("article", "brand", or "alternative_brand").
 * @param {object|null} searchedBrandData - The brand data object from Zustand.
 * @returns {boolean} - Returns true if the panel should be shown.
 */
export function shouldShowBrandPanel(searchType, searchedBrandData) {
  if (!searchedBrandData) return false;

  const hasMessage = typeof searchedBrandData.message === 'string' && searchedBrandData.message.trim().length > 0;

  const hasInfo =
    typeof searchedBrandData.info === 'string'
      ? searchedBrandData.info.trim().length > 0
      : searchedBrandData.info &&
        typeof searchedBrandData.info === 'object' &&
        Object.values(searchedBrandData.info).some(
          (value) => typeof value === 'string' && value.trim().length > 0
        );

  return (
    (searchType === 'brand' || searchType === 'alternative_brand') &&
    (hasMessage || hasInfo)
  );
}


/**
 * Formats the brand message by replacing "{brand}" with the actual brand name.
 * @param {string} message - The message string that may contain "{brand}".
 * @param {string} searchedBrand - The detected brand name.
 * @param {function} t - The translation function from react-i18next. 
 * @returns {string} - The formatted message.
 */
/*
export function formatBrandMessage(message, searchedBrand) {
  if (!message) return "";
  return message.replace(/{brand}/g, searchedBrand);
}
*/
export function formatBrandMessage(messageKey, searchedBrand, t) {
  if (!messageKey) return "";  
  const translatedMessage = t(messageKey);
  return translatedMessage.replace(/{brand}/g, searchedBrand);
}


/**
 * Formats brand info by handling line breaks properly for React.
 * @param {string} info - The info text containing potential "\n" new lines.
 * @returns {Array|string} - Returns an array of JSX elements with line breaks.
 */
export function formatBrandInfo(info) {
  if (!info) return "";

  // Si es string, continuar como hasta ahora
  if (typeof info === 'string') {
    return info.split("\n").map((line, index) => (
      <span key={index}>{line}<br /></span>
    ));
  }

  // Si es objeto, seleccionar idioma actual
  const lang = i18n.language?.toUpperCase?.() || 'EN';
  const translated = info[lang] || info.EN || "";
  return translated.split("\n").map((line, index) => (
    <span key={index}>{line}<br /></span>
  ));
}

/**
 * Checks if any of the filtered brands should display a brand info panel.
 * @param {string[]} filter__brands - List of filtered brands.
 * @param {object} brandMapping - Mapping of brand names to their details.
 * @returns {object[]} - List of valid brands with info to display.
 */
export function getFilteredBrandPanels(filter__brands, brandMapping) {
  return filter__brands
    .map(brand => ({ brand, data: brandMapping[brand] }))
    .filter(({ data }) => data && shouldShowBrandPanel("brand", data));
}