import { create } from 'zustand';

const useBrandStore = create((set, get) => ({
  brandMapping: {
    "ABB": {"brand": "ABB","in_PT": "yes","message": "","info": null},
    "Jokab": {"brand": "ABB","in_PT": "yes","message": "","info": null},
    "Stotz-Kontakt": {"brand": "ABB","in_PT": "yes","message": "","info": null},
    "Autonics": {"brand": "Autonics","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Balluff": {"brand": "Balluff","in_PT": "yes","message": "","info": null},
    "STM": {"brand": "Balluff","in_PT": "yes","message": "","info": null},
    "Sensor Technologie München": {"brand": "Balluff","in_PT": "yes","message": "","info": null},
    "SIE": {"brand": "Balluff","in_PT": "yes","message": "","info": null},
    "Baumer": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "Hübner": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "Bourdon": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "Thalheim": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "ASIA": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "MyCom": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "Verisens": {"brand": "Baumer","in_PT": "yes","message": "","info": null},
    "Beckhoff": {"brand": "Beckhoff","in_PT": "yes","message": "","info": null},
    "Belden": {"brand": "Belden","in_PT": "","message": "not_sell_this_brand","info": null},
    "Lumberg": {"brand": "Belden","in_PT": "","message": "not_sell_this_brand","info": null},
    "Hirschmann": {"brand": "Belden","in_PT": "","message": "not_sell_this_brand","info": null},
    "Bernstein": {"brand": "Bernstein","in_PT": "yes","message": "","info": null},
    "Captron": {"brand": "Captron","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Cedes neu": {"brand": "Cedes neu","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Celduc": {"brand": "Celduc","in_PT": "yes","message": "","info": null},
    "Cognex": {"brand": "Cognex","in_PT": "","message": "send_inquiry_via_email","info": null},
    "ComatReleco": {"brand": "ComatReleco","in_PT": "yes","message": "","info": null},
    "Comat": {"brand": "ComatReleco","in_PT": "yes","message": "","info": null},
    "Releco": {"brand": "ComatReleco","in_PT": "yes","message": "","info": null},
    "Comitronic-BTI": {"brand": "Comitronic-BTI","in_PT": "yes","message": "","info": null},
    "Comitronic": {"brand": "Comitronic-BTI","in_PT": "yes","message": "","info": null},
    "BTI": {"brand": "Comitronic-BTI","in_PT": "yes","message": "","info": null},
    "Contrinex": {"brand": "Contrinex","in_PT": "yes","message": "","info": {"EN": "Quantity discounts at Contrinex:\nfrom 10: +5%\nfrom 25 + 10%\nfrom 50 + 15%\nfrom 100: +18%","DE": "","FR": "","ES": ""}},
    "Crouzet": {"brand": "Crouzet","in_PT": "yes","message": "","info": {"EN": "","DE": "Mengenrabatte bei Contrinex:  \nab 10 Stück: +5 %  \nab 25 Stück: +10 %  \nab 50 Stück: +15 %  \nab 100 Stück: +18 %","FR": "Remises sur quantité chez Contrinex :  \nà partir de 10 : +5 %  \nà partir de 25 : +10 %  \nà partir de 50 : +15 %  \nà partir de 100 : +18 %","ES": "Descuentos por cantidad en Contrinex:  \ndesde 10 unidades: +5 %  \ndesde 25 unidades: +10 %  \ndesde 50 unidades: +15 %  \ndesde 100 unidades: +18 %"}},
    "Millenium": {"brand": "Crouzet","in_PT": "yes","message": "","info": {"EN": "","DE": "Mengenrabatte bei Contrinex:  \nab 10 Stück: +5 %  \nab 25 Stück: +10 %  \nab 50 Stück: +15 %  \nab 100 Stück: +18 %","FR": "Remises sur quantité chez Contrinex :  \nà partir de 10 : +5 %  \nà partir de 25 : +10 %  \nà partir de 50 : +15 %  \nà partir de 100 : +18 %","ES": "Descuentos por cantidad en Contrinex:  \ndesde 10 unidades: +5 %  \ndesde 25 unidades: +10 %  \ndesde 50 unidades: +15 %  \ndesde 100 unidades: +18 %"}},
    "Syrelec": {"brand": "Crouzet","in_PT": "yes","message": "","info": {"EN": "","DE": "Mengenrabatte bei Contrinex:  \nab 10 Stück: +5 %  \nab 25 Stück: +10 %  \nab 50 Stück: +15 %  \nab 100 Stück: +18 %","FR": "Remises sur quantité chez Contrinex :  \nà partir de 10 : +5 %  \nà partir de 25 : +10 %  \nà partir de 50 : +15 %  \nà partir de 100 : +18 %","ES": "Descuentos por cantidad en Contrinex:  \ndesde 10 unidades: +5 %  \ndesde 25 unidades: +10 %  \ndesde 50 unidades: +15 %  \ndesde 100 unidades: +18 %"}},
    "Syr-Line": {"brand": "Crouzet","in_PT": "yes","message": "","info": {"EN": "","DE": "Mengenrabatte bei Contrinex:  \nab 10 Stück: +5 %  \nab 25 Stück: +10 %  \nab 50 Stück: +15 %  \nab 100 Stück: +18 %","FR": "Remises sur quantité chez Contrinex :  \nà partir de 10 : +5 %  \nà partir de 25 : +10 %  \nà partir de 50 : +15 %  \nà partir de 100 : +18 %","ES": "Descuentos por cantidad en Contrinex:  \ndesde 10 unidades: +5 %  \ndesde 25 unidades: +10 %  \ndesde 50 unidades: +15 %  \ndesde 100 unidades: +18 %"}},
    "Crydom": {"brand": "Crydom","in_PT": "yes","message": "","info": null},
    "Sensata": {"brand": "Crydom","in_PT": "yes","message": "","info": null},
    "Datasensing (MD)": {"brand": "Datasensing (MD)","in_PT": "yes","message": "","info": null},
    "Datalogic": {"brand": "Datasensing (MD)","in_PT": "yes","message": "","info": null},
    "MD": {"brand": "Datasensing (MD)","in_PT": "yes","message": "","info": null},
    "Diell": {"brand": "Datasensing (MD)","in_PT": "yes","message": "","info": null},
    "Disoric": {"brand": "Disoric","in_PT": "yes","message": "","info": null},
    "di-soric": {"brand": "Disoric","in_PT": "yes","message": "","info": null},
    "Dold": {"brand": "Dold","in_PT": "yes","message": "","info": null},
    "E. Dold & Söhne": {"brand": "Dold","in_PT": "yes","message": "","info": null},
    "Eaton/Moeller": {"brand": "Eaton/Moeller","in_PT": "yes","message": "","info": null},
    "Eaton": {"brand": "Eaton/Moeller","in_PT": "yes","message": "","info": null},
    "Moeller": {"brand": "Eaton/Moeller","in_PT": "yes","message": "","info": null},
    "Möller": {"brand": "Eaton/Moeller","in_PT": "yes","message": "","info": null},
    "Eickmann": {"brand": "Eickmann","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Elap": {"brand": "Elap","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Elobau": {"brand": "Elobau","in_PT": "","message": "not_sell_this_brand","info": null},
    "Endress&Hauser": {"brand": "Endress&Hauser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Endress": {"brand": "Endress&Hauser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Hauser": {"brand": "Endress&Hauser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Endress+Hauser": {"brand": "Endress&Hauser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "E+H": {"brand": "Endress&Hauser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "E&H": {"brand": "Endress&Hauser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Escha": {"brand": "Escha","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Euchner": {"brand": "Euchner","in_PT": "yes","message": "","info": null},
    "Eurotherm": {"brand": "Eurotherm","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Festo": {"brand": "Festo","in_PT": "","message": "not_sell_this_brand","info": null},
    "Finder": {"brand": "Finder","in_PT": "yes","message": "","info": null},
    "Gavazzi": {"brand": "Gavazzi","in_PT": "yes","message": "","info": null},
    "Carlo": {"brand": "Gavazzi","in_PT": "yes","message": "","info": null},
    "Gefran": {"brand": "Gefran","in_PT": "","message": "not_sell_this_brand","info": null},
    "Harting": {"brand": "Harting","in_PT": "","message": "not_sell_this_brand","info": null},
    "Heimholz": {"brand": "Heimholz","in_PT": "","message": "not_sell_this_brand","info": null},
    "Hengstler Drehgeb. ": {"brand": "Hengstler Drehgeb. ","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Hengstler Zähler": {"brand": "Hengstler Zähler","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Hohner": {"brand": "Hohner","in_PT": "","message": "not_sell_this_brand","info": null},
    "Honeywell": {"brand": "Honeywell","in_PT": "","message": "not_sell_this_brand","info": null},
    "IDEC": {"brand": "IDEC","in_PT": "yes","message": "","info": null},
    "Apem": {"brand": "IDEC","in_PT": "yes","message": "","info": null},
    "ifm": {"brand": "ifm","in_PT": "yes","message": "","info": null},
    "InXpect": {"brand": "InXpect","in_PT": "","message": "not_sell_this_brand","info": null},
    "ipf": {"brand": "ipf","in_PT": "yes","message": "","info": null},
    "JUMO": {"brand": "JUMO","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Keyence": {"brand": "Keyence","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Klaschka": {"brand": "Klaschka","in_PT": "","message": "send_inquiry_via_email","info": null},
    "KSR Kübler": {"brand": "KSR Kübler","in_PT": "","message": "not_sell_this_brand","info": {"EN": "Please send us enquiries only for encoders from Fritz Kübler GmbH vom Villingen, Germany.\n\nWe do not sell: KSR Kübler (Zwingenberg), Kübler (Switzerland)","DE": "","FR": "","ES": ""}},
    "KSR": {"brand": "KSR Kübler","in_PT": "","message": "not_sell_this_brand","info": {"EN": "Please send us enquiries only for encoders from Fritz Kübler GmbH vom Villingen, Germany.\n\nWe do not sell: KSR Kübler (Zwingenberg), Kübler (Switzerland)","DE": "","FR": "","ES": ""}},
    "Kübler": {"brand": "Kübler","in_PT": "","message": "","info": {"EN": "Please send us enquiries only for encoders from Fritz Kübler GmbH vom Villingen, Germany.\n\nWe do not sell: KSR Kübler (Zwingenberg), Kübler (Switzerland)","DE": "","FR": "","ES": ""}},
    "Kubler": {"brand": "Kübler","in_PT": "","message": "","info": {"EN": "Please send us enquiries only for encoders from Fritz Kübler GmbH vom Villingen, Germany.\n\nWe do not sell: KSR Kübler (Zwingenberg), Kübler (Switzerland)","DE": "","FR": "","ES": ""}},
    "Kuebler": {"brand": "Kübler","in_PT": "","message": "","info": {"EN": "Please send us enquiries only for encoders from Fritz Kübler GmbH vom Villingen, Germany.\n\nWe do not sell: KSR Kübler (Zwingenberg), Kübler (Switzerland)","DE": "","FR": "","ES": ""}},
    "Lapp": {"brand": "Lapp","in_PT": "","message": "send_inquiry_via_email","info": null},
    "LED2Work": {"brand": "LED2Work","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Legrand": {"brand": "Legrand","in_PT": "","message": "not_sell_this_brand","info": null},
    "Leine&Linde": {"brand": "Leine&Linde","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Leine Linde": {"brand": "Leine&Linde","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Leine+Linde": {"brand": "Leine&Linde","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Leine & Linde": {"brand": "Leine&Linde","in_PT": "","message": "send_inquiry_via_email","info": null},
    "L&L": {"brand": "Leine&Linde","in_PT": "","message": "send_inquiry_via_email","info": null},
    "L+L": {"brand": "Leine&Linde","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Lenze": {"brand": "Lenze","in_PT": "","message": "not_sell_this_brand","info": {"EN": "","DE": "Bitte senden Sie uns Anfragen ausschließlich für Drehgeber der Fritz Kübler GmbH aus Villingen, Deutschland.\n\nWir verkaufen nicht: KSR Kübler (Zwingenberg), Kübler (Schweiz).","FR": "Veuillez nous envoyer uniquement des demandes concernant les codeurs de Fritz Kübler GmbH à Villingen, Allemagne.\n\nNous ne vendons pas : KSR Kübler (Zwingenberg), Kübler (Suisse).","ES": "Por favor, envíenos consultas únicamente para encoders de Fritz Kübler GmbH de Villingen, Alemania.\n\nNo vendemos: KSR Kübler (Zwingenberg), Kübler (Suiza)."}},
    "Leuze": {"brand": "Leuze","in_PT": "yes","message": "","info": {"EN": "","DE": "Bitte senden Sie uns Anfragen ausschließlich für Drehgeber der Fritz Kübler GmbH aus Villingen, Deutschland.\n\nWir verkaufen nicht: KSR Kübler (Zwingenberg), Kübler (Schweiz).","FR": "Veuillez nous envoyer uniquement des demandes concernant les codeurs de Fritz Kübler GmbH à Villingen, Allemagne.\n\nNous ne vendons pas : KSR Kübler (Zwingenberg), Kübler (Suisse).","ES": "Por favor, envíenos consultas únicamente para encoders de Fritz Kübler GmbH de Villingen, Alemania.\n\nNo vendemos: KSR Kübler (Zwingenberg), Kübler (Suiza)."}},
    "Lion Precision": {"brand": "Lion Precision","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Lütze": {"brand": "Lütze","in_PT": "","message": "not_sell_this_brand","info": null},
    "Lutze": {"brand": "Lütze","in_PT": "","message": "not_sell_this_brand","info": null},
    "Meanwell": {"brand": "Meanwell","in_PT": "","message": "not_sell_this_brand","info": null},
    "Micro-Epsilon": {"brand": "Micro-Epsilon","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Eltrotec": {"brand": "Micro-Epsilon","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Microsonic": {"brand": "Microsonic","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Mitsubishi": {"brand": "Mitsubishi","in_PT": "","message": "not_sell_this_brand","info": null},
    "Murr": {"brand": "Murr","in_PT": "yes","message": "","info": null},
    "Omron": {"brand": "Omron","in_PT": "yes","message": "","info": null},
    "Microscan": {"brand": "Omron","in_PT": "yes","message": "","info": null},
    "Optris": {"brand": "Optris","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Panasonic": {"brand": "Panasonic","in_PT": "","message": "send_inquiry_via_email","info": null},
    "NAIS": {"brand": "Panasonic","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Matsushita electric works": {"brand": "Panasonic","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Sunx": {"brand": "Panasonic","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Pantron": {"brand": "Pantron","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Pepperl+Fuchs": {"brand": "Pepperl+Fuchs","in_PT": "yes","message": "","info": null},
    "Pepperl & Fuchs": {"brand": "Pepperl+Fuchs","in_PT": "yes","message": "","info": null},
    "P+F": {"brand": "Pepperl+Fuchs","in_PT": "yes","message": "","info": null},
    "P&F": {"brand": "Pepperl+Fuchs","in_PT": "yes","message": "","info": null},
    "Pepperl": {"brand": "Pepperl+Fuchs","in_PT": "yes","message": "","info": null},
    "Visolux": {"brand": "Pepperl+Fuchs","in_PT": "yes","message": "","info": null},
    "Peter": {"brand": "Peter","in_PT": "","message": "not_sell_this_brand","info": null},
    "Phoenix": {"brand": "Phoenix","in_PT": "yes","message": "","info": null},
    "Phoenix contact": {"brand": "Phoenix","in_PT": "yes","message": "","info": null},
    "PIL": {"brand": "PIL","in_PT": "","message": "send_inquiry_via_email","info": null},
    "inelta Sensorsysteme": {"brand": "PIL","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Pilz": {"brand": "Pilz","in_PT": "yes","message": "","info": null},
    "Pizzato": {"brand": "Pizzato","in_PT": "","message": "not_sell_this_brand","info": null},
    "Puls": {"brand": "Puls","in_PT": "yes","message": "","info": null},
    "Pulsotronic": {"brand": "Pulsotronic","in_PT": "yes","message": "","info": null},
    "Rechner": {"brand": "Rechner","in_PT": "yes","message": "","info": {"EN": "Please note that Rechner works with surcharges on the list price for quantities of less than 6 pieces. \n- 1-5 pieces: -70% means a surcharge. \n- From 6 pieces: 5% discount.","DE": "","FR": "","ES": ""}},
    "Rockwell": {"brand": "Rockwell","in_PT": "yes","message": "","info": null},
    "Guardmaster": {"brand": "Rockwell","in_PT": "yes","message": "","info": null},
    "Tesch": {"brand": "Rockwell","in_PT": "yes","message": "","info": null},
    "Allan Bradley": {"brand": "Rockwell","in_PT": "yes","message": "","info": null},
    "S&D": {"brand": "Rockwell","in_PT": "yes","message": "","info": null},
    "Sprecher & Schuh": {"brand": "Rockwell","in_PT": "yes","message": "","info": null},
    "Schleicher": {"brand": "Schleicher","in_PT": "","message": "not_sell_this_brand","info": {"EN": "","DE": "Bitte beachten Sie, dass Rechner für Stückzahlen unter 6 einen Aufschlag auf den Listenpreis erhebt. \n- 1-5 Stück: -70 % bedeutet einen Aufschlag.\n- Ab 6 Stück: 5 % Rabatt.","FR": "Veuillez noter que Rechner applique des majorations sur le prix catalogue pour des quantités inférieures à 6 pièces. \n- De 1 à 5 pièces : -70 % indique une majoration.\n- À partir de 6 pièces : 5 % de remise.","ES": "Tenga en cuenta que Rechner aplica recargos sobre el precio de lista para cantidades inferiores a 6 unidades. \n- De 1 a 5 unidades: -70% indica un recargo.\n- Desde 6 unidades: 5% de descuento."}},
    "Schmersal": {"brand": "Schmersal","in_PT": "yes","message": "","info": null},
    "K.A.Schmersal": {"brand": "Schmersal","in_PT": "yes","message": "","info": null},
    "Schneider": {"brand": "Schneider","in_PT": "yes","message": "","info": null},
    "Schönbuch": {"brand": "Schönbuch","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Schoenbuch": {"brand": "Schönbuch","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Schukat": {"brand": "Schukat","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Schunk": {"brand": "Schunk","in_PT": "","message": "not_sell_this_brand","info": null},
    "Selet": {"brand": "Selet","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Sensopart": {"brand": "Sensopart","in_PT": "yes","message": "","info": null},
    "Sick": {"brand": "Sick","in_PT": "yes","message": "","info": null},
    "Erwin Sick": {"brand": "Sick","in_PT": "yes","message": "","info": null},
    "Stegmann": {"brand": "Sick","in_PT": "yes","message": "","info": null},
    "Siemens": {"brand": "Siemens","in_PT": "yes","message": "","info": null},
    "SMC": {"brand": "SMC","in_PT": "","message": "not_sell_this_brand","info": null},
    "Steute": {"brand": "Steute","in_PT": "yes","message": "","info": null},
    "Telco": {"brand": "Telco","in_PT": "yes","message": "","info": null},
    "Sitron": {"brand": "Telco","in_PT": "yes","message": "","info": null},
    "Telemecanique": {"brand": "Telemecanique","in_PT": "yes","message": "","info": null},
    "Dinel": {"brand": "Telemecanique","in_PT": "yes","message": "","info": null},
    "Tesensor": {"brand": "Telemecanique","in_PT": "yes","message": "","info": null},
    "Yageo": {"brand": "Telemecanique","in_PT": "yes","message": "","info": null},
    "Berger Lahr": {"brand": "Telemecanique","in_PT": "yes","message": "","info": null},
    "Hyde Park": {"brand": "Telemecanique","in_PT": "yes","message": "","info": null},
    "TR electronic": {"brand": "TR electronic","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Turck": {"brand": "Turck","in_PT": "yes","message": "","info": null},
    "Banner": {"brand": "Turck","in_PT": "yes","message": "","info": null},
    "Universal Robots": {"brand": "Universal Robots","in_PT": "","message": "","info": null},
    "UniversalRobots": {"brand": "Universal Robots","in_PT": "","message": "","info": null},
    "Vega": {"brand": "Vega","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Wachendorff": {"brand": "Wachendorff","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Red Lion": {"brand": "Wachendorff","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Ewon": {"brand": "Wachendorff","in_PT": "","message": "send_inquiry_via_email","info": null},
    "ADF": {"brand": "Wachendorff","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Wago": {"brand": "Wago","in_PT": "yes","message": "","info": null},
    "Waldmann": {"brand": "Waldmann","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Weidmüller": {"brand": "Weidmüller","in_PT": "yes","message": "","info": null},
    "Weidmueller": {"brand": "Weidmüller","in_PT": "yes","message": "","info": null},
    "Wenglor": {"brand": "Wenglor","in_PT": "yes","message": "","info": null},
    "Werma": {"brand": "Werma","in_PT": "yes","message": "","info": null},
    "Wieland": {"brand": "Wieland","in_PT": "","message": "not_sell_this_brand","info": null},
    "Wika": {"brand": "Wika","in_PT": "","message": "not_sell_this_brand","info": null},
    "Xecro": {"brand": "Xecro","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Yaskawa": {"brand": "Yaskawa","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Vipa": {"brand": "Yaskawa","in_PT": "","message": "send_inquiry_via_email","info": null},
    "Z-Laser": {"brand": "Z-Laser","in_PT": "","message": "send_inquiry_via_email","info": null},
    "zlaser": {"brand": "Z-Laser","in_PT": "","message": "send_inquiry_via_email","info": null}
  },
setBrandMapping: (newSet) => set({ brandMapping: newSet }),
searchedBrandData: null,
setSearchedBrandData: (data) => {
  set({ searchedBrandData: data });
  //console.log('setting searchedBrandData to:', data);
},
  /**
   * Get data from brandMapping for brands of "filter__brands".
   * Return only brands that have 'message' or 'info'.
   * @param {Array<string>} brands - Filtered brands.
   * @returns {Array<object>} - Array of brand data.
   */
  getFilteredBrandData: (brands) => {
    const { brandMapping } = get();
    return brands
      .map((brand) => brandMapping[brand])
      .filter((data) => data && (data.message || data.info));
  },


}));



// Expose the store in the window object for debugging
// Usage: 
// - window.useStore.getState()
// - window.useStore.getState().searchResults
if (typeof window !== "undefined" && (window.location.hostname === "localhost" || window.Cypress)) {
  // @ts-ignore
  window.brandStore = useBrandStore;
}

export default useBrandStore;
