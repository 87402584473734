import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import useStore from '../../store/store';
import useBrandStore from '../../store/brandStore';
import { getFilteredBrandPanels } from '../../services/brandsService';
import { shouldShowBrandPanel, formatBrandMessage, formatBrandInfo } from '../../services/brandsService';
import { useTranslation } from 'react-i18next';

const BrandInfoPanel = () => {
  const { t } = useTranslation();
  const { searchedBrand, searchType, filter__brands, brands_in_results } = useStore();
  const { brandMapping, searchedBrandData } = useBrandStore();
  const [brandDataList, setBrandDataList] = useState([]);

  useEffect(() => {
    const validBrands = new Map(); // 🚀 Usa un Map para evitar duplicados
  
    // CASE 1: Add searched brand if it has info or message
    if (searchedBrand && shouldShowBrandPanel(searchType, brandMapping[searchedBrand])) {
      validBrands.set(searchedBrand, { brand: searchedBrand, data: brandMapping[searchedBrand] });
    }
  
    // CASE 2: Add filtered brand panels
    getFilteredBrandPanels(filter__brands, brandMapping).forEach(({ brand, data }) => {
      validBrands.set(brand, { brand, data });
    });
  
 
    // CASE 3: Add brand from search results if only 'one brand' is found
    if (
      brands_in_results.length === 1 &&
      searchedBrandData &&
      brands_in_results[0].brand === searchedBrandData.brand &&
      (searchedBrandData.message || searchedBrandData.info)
    ) {
      validBrands.set(searchedBrandData.brand, {
        brand: searchedBrandData.brand,
        data: searchedBrandData
      });
    }

   
    setBrandDataList(Array.from(validBrands.values())); // 🚀 Convertir el Map a array y actualizar estado
  }, [searchedBrand, searchType, filter__brands, brandMapping, brands_in_results, searchedBrandData]);
  

  if (brandDataList.length === 0) return null;

  return (
    <>
      {brandDataList.map(({ brand, data }) => (
        <TableContainer key={brand} component={Paper} sx={{ 
          marginTop: 3, 
          backgroundColor: '#f8f9fa', 
          boxShadow: '0px 2px 8px rgba(0,0,0,0.2)', 
          padding: '12px',
          borderRadius: '8px'
        }}>
          <Table size="small" aria-label="brand info panel">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#FFF", background: "#1976d2", fontSize: 16, fontWeight: 'bold' }}>
                  {brand}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.message && (
                <TableRow>
                  <TableCell sx={{ background: "#ffffff", fontSize: 14, padding: '10px', color: '#333' }}>
                    <Typography sx={{ fontWeight: 'normal' }}>{formatBrandMessage(data.message, brand, t)}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {data.info && (
                <TableRow>
                  <TableCell sx={{ background: "#ffffff", fontSize: 14, padding: '10px', color: '#333' }}>
                    {formatBrandInfo(data.info).map((line, index) => (
                      <Typography key={index} sx={{ display: 'block' }}>{line}</Typography>
                    ))}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
};

export default BrandInfoPanel;
