import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const Faqs = () => {
  const { t } = useTranslation();

  const accordionStyle = {
    accordion: {
      boxShadow: "none",
    },
    summary: {
      fontWeight: "bold",
      borderTop: "1px solid rgba(220, 220, 220, 0.6)",
    },
    details: {
      backgroundColor: "rgba(245, 245, 245, 0.8)",      
    },
  };

  return (
    <div>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: "bold", my: 2 }}>
          {t('faqsTitle')}
        </Typography>
            
        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            {t('faq1Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq1Answer')}
          </AccordionDetails>
        </Accordion>
        
        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
            {t('faq2Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq2Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
            {t('faq3Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq3Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
            {t('faq4Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq4Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content" id="panel5-header">
            {t('faq5Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq5Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel6-content" id="panel6-header">
            {t('faq6Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq6Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel7-content" id="panel7-header">
            {t('faq7Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq7Answer')}
          </AccordionDetails>
        </Accordion>        

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel8-content" id="panel8-header">
            {t('faq8Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq8Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel9-content" id="panel9-header">
            {t('faq9Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq9Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel10-content" id="panel10-header">
            {t('faq10Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq10Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel11-content" id="panel11-header">
            {t('faq11Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq11Answer')}
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters={true} square={true} style={accordionStyle.accordion}>
          <AccordionSummary style={accordionStyle.summary} expandIcon={<ExpandMoreIcon />} aria-controls="panel12-content" id="panel12-header">
            {t('faq12Question')}
          </AccordionSummary>
          <AccordionDetails style={accordionStyle.details}>
            {t('faq12Answer')}
          </AccordionDetails>
        </Accordion>
  
      </Box>
    </div>
  );
};

export default Faqs;
