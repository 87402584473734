import React from "react";
import { Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import useStore from "../../store/store";
import SearchResultsItem from "./SearchResultsItem";
import FilterChips from "./FilterChips";
import { useTranslation } from "react-i18next";
import SearchErrorMessage from "./SearchErrorMessage";

const SearchResultsList = () => {
  const { t } = useTranslation();
  const {
    searchResults,
    currentPage,
    setCurrentPage,
    resultsPerPage,
    setResetAccordions,
    isLoadingResults,
    isReplacementSearch,
    searchErrorType,
  } = useStore();
  
  const filteredResults = searchResults;

  const paginatedResults = filteredResults.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );
  const handleChangePage = (event, value) => {
    // Close all expanded accordions:
    setResetAccordions(true);
    setTimeout(() => {setResetAccordions(false);}, 100);
    // Change page:
    setCurrentPage(value);
  };

  return (
    <>
      <TableContainer component={Paper} data-testid="search-results-list">
      <Table size="small" aria-label="Articles found">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "#FFF", fontSize: 12, backgroundColor: isReplacementSearch ? '#F00' : '#000'}}>

              {isLoadingResults && !isReplacementSearch && (
                <Typography sx={{fontSize: 14}}>
                  {t('loading')} ...
                </Typography>
              )}

              {isLoadingResults && isReplacementSearch && (
                <Typography sx={{fontSize: 14}}>
                  {t('loadingReplacements')} ...
                </Typography>
              )}

              {!isLoadingResults && searchResults.length === 0 && (
                <Typography sx={{fontSize: 14}}>
                  {t('resultListNoArticlesFound')}
                </Typography>
              )}
              
              {!isLoadingResults && searchResults.length === 1 && !isReplacementSearch && (
                <Typography sx={{fontSize: 14}}>
                  {t('resultLArticleFound')}:
                </Typography>
              )}
              
              {!isLoadingResults && searchResults.length === 1 && isReplacementSearch && (
                <Typography sx={{fontSize: 14}}>
                  {t('resultReplacementFound')}:
                </Typography>
              )}

              {!isLoadingResults && searchResults.length > 1 && !isReplacementSearch && (
                <Typography sx={{fontSize: 14}}>
                  {t('resultArticlesNotMatchFound')}
                </Typography>
              )}
              {!isLoadingResults && searchResults.length > 1 && isReplacementSearch && (
                <Typography sx={{fontSize: 14}}>
                  {t('resultReplacementsFound')}
                </Typography>
              )}

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {(searchResults.length === 0 || searchErrorType ) && (
            <TableRow>
              <TableCell sx={{ fontSize: 12, height: '284px', paddingBottom: '20px' }}>
                <SearchErrorMessage />
              </TableCell>
            </TableRow>
          )}

          {searchResults.length > 0 && (
            <TableRow>
              <TableCell sx={{ fontSize: 12 }}>
                <Box>
                  <Box>
                    <FilterChips />
                  </Box>
                  {/*}
                  <Typography sx={{ fontSize: 14, textAlign: 'right', fontWeight: 'bold' }}>Page {currentPage} of {Math.ceil(searchResults.length / resultsPerPage)}</Typography>
                  */}
                </Box>
              </TableCell>
            </TableRow>
          )}

          {paginatedResults.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              data-testid="search-result"
            >
              <TableCell sx={{ pt: 1.5, pb: 0.6, pl: 2, pr: 2 }}>
                <SearchResultsItem key={index} row={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      <Box>
        {filteredResults.length > resultsPerPage && (
          <Pagination
            count={Math.ceil(filteredResults.length / resultsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            sx={{ marginTop: 2 }}
          />            
        )}
      </Box>
    </>
  );
};

export default SearchResultsList;
