import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useStore from '../store/store';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import i18n from 'i18next';

const LanguageSelector = () => {
  const { language, setLanguage } = useStore.getState(); 

  const handleLanguageChange = (language) => {
    setLanguage(language);
  };

  React.useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
  }, [language]);

  const defaultTheme = createTheme({
    palette: {
      primary: { 
        main: '#1F56AE' 
      },
      secondary: { 
        main: '#AAA' 
      }
    },
    typography: {}
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LanguageIcon sx={{ mr: 0.5, color: 'primary.main' }} />
        <ButtonGroup variant="text" size='small' aria-label="Language selector" style={{ display: 'inline-block' }}>
          <Button onClick={() => handleLanguageChange('EN')} color={language === 'EN' ? 'primary' : 'secondary'}>EN</Button>
          <Button onClick={() => handleLanguageChange('DE')} color={language === 'DE' ? 'primary' : 'secondary'}>DE</Button>          
          <Button onClick={() => handleLanguageChange('FR')} color={language === 'FR' ? 'primary' : 'secondary'}>FR</Button>
          <Button onClick={() => handleLanguageChange('ES')} color={language === 'ES' ? 'primary' : 'secondary'}>ES</Button>
        </ButtonGroup>
      </Box>
    </ThemeProvider>
  );
};

export default LanguageSelector;
