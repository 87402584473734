import React from 'react';
import { Box, Grid } from '@mui/material';
import useStore from '../../store/store';
import useBrandStore from '../../store/brandStore';
import SearchResultsList from './SearchResultsList';
import SearchFilter from './SearchFilter';
import BrandInfoPanel from './BrandInfoPanel';

const SearchResultsPage = () => {
  const {
    hasSearched,
    searchedBrand,
    searchError,
    searchErrorType,
    filter__brands,
    brands_in_results,
  } = useStore();

  const { searchedBrandData } = useBrandStore();

  // Contemplate 3 scenarios to show the brand panel:
  // 1. Brand search message
  // 2. Searched brand panels
  // 3. Filtered brand panel
  const showBrandPanel = 
    (searchedBrand && !searchError && searchErrorType !== 'no_results') || 
    filter__brands.length > 0 ||
    (!searchedBrand && brands_in_results.length === 1 && searchedBrandData && (searchedBrandData.message || searchedBrandData.info)); 


  return (
    <>
      {hasSearched && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {/* Left panel with filters and brand message */}
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Box sx={{ backgroundColor: 'white', marginBottom: 2 }} data-testid="search-filter">
                  <SearchFilter />
                </Box>
                {showBrandPanel && (
                  <Box sx={{ backgroundColor: 'white' }} data-testid="brand-message">
                    <BrandInfoPanel />
                  </Box>
                )}
              </Box>
            </Grid>

            {/* Right panel with search results */}
            <Grid item xs={12} sm={12} md={9}>
              <Box>
                <Box sx={{ backgroundColor: 'transparent' }} data-testid="search-results">
                  <SearchResultsList />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default SearchResultsPage;