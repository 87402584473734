import toast from 'react-hot-toast';
import config from '../config';
//import { countArticlesService } from './countArticlesService';
import { brandsService } from './brandsService';
import useStore from '../store/store';

export const handleSubmitService = async (e, setToken, setUser, navigate) => {
  e.preventDefault();
  const {
    setIsLoadingUser,
    loginAttempts,
    incrementLoginAttempts,
    resetLoginAttempts,
    language,
  } = useStore.getState(); 
  
  const maxAttempts = 3;
  if (loginAttempts >= maxAttempts) {
    toast.error('Too many login attempts. Please contact us if you are still having troubles.');
    setTimeout(() => {
      resetLoginAttempts();
    }, 10 * 1000);  // 15 minutos
    resetLoginAttempts();
    return;
  }
  
  const data = new FormData(e.currentTarget);
  const useremail = data.get("email");
  const passwordhash = data.get("password");

  if (!useremail || !passwordhash) {
    setIsLoadingUser(false);
    toast.error("Email and password required");
    /* toast.error('Email and password required',
      { style: {borderRadius: '10px',background: '#F00',color: '#FFF',},}
    ); */
    return;
  }

  setIsLoadingUser(true);
  try {
    const response = await fetch(`${config.serverUrl}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json", 'X-Selected-Language': language },
      body: JSON.stringify({ useremail, passwordhash }),
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      toast.error(errorData.error);
      setIsLoadingUser(false);
      incrementLoginAttempts();
      return;
    }
    
    toast.success('Login successful!');
    const data = await response.json();
    setToken(data.token);
    setUser(data.user);
    resetLoginAttempts();
    
    // Count articles:
    //await countArticlesService(data.token);

    // Brand list from DB:
    await brandsService(data.token);
    
    // GoTo home:
    setTimeout(() => {
      setIsLoadingUser(false);
      navigate("/");
    }, 750);
          
  } catch (error) {
    toast.error('An unexpected error occurred');
    setIsLoadingUser(false);
  }
};
